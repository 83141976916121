import { useQuery, useLazyQuery } from '@apollo/client'
import { isPast, isFuture, parseISO, formatISO } from 'date-fns'
import { GET_CHALLENGES, GET_CHALLENGE, GET_FINISHERS } from '../queries'
import { Challenge, ChallengeVariables } from '../queries/types/Challenge'
import {
  Challenges,
  Challenges_challenges as TChallenges,
} from '../queries/types/Challenges'
import { Finishers, FinishersVariables } from '../queries/types/Finishers'

type OptionalPartnerId = {
  partnerId?: string
}

const clientDate = formatISO(new Date())

const useChallenges = ({ partnerId }: OptionalPartnerId = {}) => {
  const { loading, error, data } = useQuery<Challenges>(GET_CHALLENGES, {
    variables: { partnerId, v2Flag: true },
  })

  const challenges = data?.challenges || []

  const cleanChallenges = challenges.filter(val => !!val) as [TChallenges]

  return { loading, error, challenges: cleanChallenges }
}

const useChallenge = (variables: ChallengeVariables) => {
  const { loading, error, data } = useQuery<Challenge>(GET_CHALLENGE, {
    variables: { clientDate, ...variables, v2Flag: true },
  })

  const challenge = data?.challenge

  let hasChallengeEnded = false
  if (challenge && isPast(parseISO(challenge.endTime as string))) {
    hasChallengeEnded = true
  }

  return { loading, error, challenge, hasChallengeEnded }
}

const useTop5Instructors = ({ challengeId }: ChallengeVariables) => {
  const { loading, error, challenge } = useChallenge({ challengeId })

  const data = challenge?.top5Instructors || []
  const hasTop5Instructors = !(loading || error) && data.length
  const top5Instructors = data.map((instructor, i) => ({
    x: i + 1,
    y: instructor.rideCount,
    label: `${instructor.firstName} ${instructor.lastName}`,
  }))

  return { loading, error, hasTop5Instructors, top5Instructors }
}

const useTop5Classes = ({ challengeId }: ChallengeVariables) => {
  const { loading, error, challenge } = useChallenge({ challengeId })

  const data = challenge?.top5Classes || []
  const hasTop5Classes = !(loading || error) && data.length
  const top5Classes = data.map((cls, i) => ({
    x: i + 1,
    y: cls.classesTakenCount,
    label: cls.rideTitle,
  }))

  return { loading, error, hasTop5Classes, top5Classes }
}

const useHasChallenges = ({ partnerId }: OptionalPartnerId = {}) => {
  const { challenges } = useChallenges({ partnerId })

  if (challenges.length) {
    return true
  }

  return false
}

const useLazyFinishers = (variables: FinishersVariables) => {
  const { challenge } = useChallenge(variables)
  const [getFinishers, { loading, error, data }] = useLazyQuery<Finishers>(
    GET_FINISHERS,
    {
      variables: {
        ...variables,
        v2Flag: true,
      },
    },
  )

  const exportFinishers = () => {
    getFinishers()
      .then(({ data: d }) => {
        const finishers = d?.finishers || []
        const csv = ['firstName,lastName,email,corpEmail']
          .concat(
            finishers.map(finisher =>
              [
                finisher?.firstName,
                finisher?.lastName,
                finisher?.email,
                finisher?.companyEmail,
              ].join(','),
            ),
          )
          .join('\n')

        const file = new Blob([csv], { type: 'text/csv' })
        const downloadLink = document.createElement('a')
        downloadLink.download = `${challenge ? challenge.title : 'finishers_list'}.csv`
        downloadLink.href = window.URL.createObjectURL(file)
        downloadLink.style.display = 'none'
        document.body.appendChild(downloadLink)
        downloadLink.click()
        document.body.removeChild(downloadLink)
      })
      // eslint-disable-next-line no-console
      .catch(err => console.warn('failed to download finishers list', err))
  }

  return { exportFinishers, loading, error, data }
}

const useActiveChallenge = () => {
  const { loading, error, challenges } = useChallenges()

  const activeChallenge = challenges
    .filter(
      val =>
        isPast(parseISO(val.startTime as string)) &&
        isFuture(parseISO(val.endTime as string)),
    )
    .shift()

  return { loading, error, activeChallenge }
}

export {
  clientDate,
  useChallenges,
  useChallenge,
  useTop5Instructors,
  useTop5Classes,
  useHasChallenges,
  useLazyFinishers,
  useActiveChallenge,
}
