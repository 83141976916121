import { gql } from '@apollo/client'

const GET_CHALLENGES = gql`
  query Challenges($partnerId: String, $v2Flag: Boolean) {
    challenges(partnerId: $partnerId, v2Flag: $v2Flag) {
      id
      title
      description
      startTime
      endTime
    }
  }
`

const GET_CHALLENGE = gql`
  query Challenge($challengeId: String!, $clientDate: DateTime, $v2Flag: Boolean) {
    challenge(challengeId: $challengeId, clientDate: $clientDate, v2Flag: $v2Flag) {
      id
      title
      description
      startTime
      endTime
      employeeCount
      participantCount
      completedCount
      classesCompletedCount
      totalMinutes
      top5Classes {
        rideTitle
        classesTakenCount
      }
      top5Instructors {
        firstName
        lastName
        rideCount
      }
      daysElapsed
      daysTotal
    }
  }
`

const GET_FINISHERS = gql`
  query Finishers($challengeId: String!, $partnerId: String, $v2Flag: Boolean) {
    finishers(challengeId: $challengeId, partnerId: $partnerId, v2Flag: $v2Flag) {
      firstName
      lastName
      email
      companyEmail
    }
  }
`

export { GET_CHALLENGES, GET_CHALLENGE, GET_FINISHERS }
